<template>
<div  class="pa-10">

  <dashboardstats/>
</div>
</template>
<script>
import dashboardstats from "@/components/dashboard_stats";
export default {
  components: {
    dashboardstats,
  },
};
</script>
