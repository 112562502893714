<!-- 
System: Whistle It
Developer: Hassan Ali
Date: 17-06-2021 
Organization: Whistle it
Purpose: Component for displaying application stats
-->

<template>
  <v-container class="pa-0">
    <v-row>
      <v-col cols="12">
        <v-toolbar flat>
          <span class="title font-weight-medium">Filters:</span>
          <v-spacer></v-spacer>

          <v-chip
            class="ma-4"
            v-if="isActive"
            color="#423D68"
            text-color="white"
          >
            <v-avatar left>
              <v-icon>mdi-calendar</v-icon>
            </v-avatar>
            {{ dateRangeText }}
            <v-icon class="ml-2" size="22" @click="deleteComponent">
              mdi-close</v-icon
            >
          </v-chip>

          <v-menu
            down
            offset-y
            :close-on-content-click="false"
            class="d-flex flex-column"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ma-3" v-bind="attrs" v-on="on"
                >mdi-filter-menu</v-icon
              >
            </template>
            <v-card>
              <v-date-picker v-model="dates" range></v-date-picker>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="primary" text @click="isActive = true;menu=false">
                  Select
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <!-- Companies -->
      <v-col sm="6" md="6" lg="4">
        <v-card elevation="0">
          <v-toolbar flat>
            <v-toolbar-title class="body-1 font-weight-medium"
              >Companies
            </v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <span class="display-2 black--text d-flex justify-center">{{
              companies
            }}</span>

            <span class="d-flex justify-center success--text my-3"
              ><v-icon color="success">mdi-arrow-up</v-icon> 2.3</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <!-- End of Companies -->
      <!-- Users -->
      <v-col sm="6" md="6" lg="4">
        <v-card elevation="0">
          <v-toolbar flat>
            <v-toolbar-title class="body-1 font-weight-medium"
              >Users</v-toolbar-title
            >
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <span class="display-2 black--text d-flex justify-center">{{
              users
            }}</span>
            <span class="d-flex justify-center red--text my-3"
              ><v-icon color="red">mdi-arrow-down</v-icon> 2.3</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <!-- End of Users -->
      <!-- Public channels -->
      <v-col sm="6" md="6" lg="4">
        <v-card elevation="0">
          <v-toolbar flat>
            <v-toolbar-title class="body-1 font-weight-medium"
              >Public Channels</v-toolbar-title
            >
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <span class="display-2 black--text d-flex justify-center">{{
              publicChannels
            }}</span>
            <span class="d-flex justify-center success--text my-3"
              ><v-icon color="success">mdi-arrow-up</v-icon> 4.3</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <!-- End of Public channels -->
      <!-- Teams -->
      <v-col sm="6" md="6" lg="4">
        <v-card elevation="0">
          <v-toolbar flat>
            <v-toolbar-title class="body-1 font-weight-medium"
              >Teams</v-toolbar-title
            >
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <span class="display-2 black--text d-flex justify-center">{{
              teams
            }}</span>
            <span class="d-flex justify-center success--text my-3"
              ><v-icon color="success">mdi-arrow-up</v-icon> 5.3</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <!-- End of Teams -->
      <!-- Private Channels -->
      <v-col sm="6" md="6" lg="4">
        <v-card elevation="0">
          <v-toolbar flat>
            <v-toolbar-title class="body-1 font-weight-medium"
              >Private Channels</v-toolbar-title
            >
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <span class="display-2 black--text d-flex justify-center">{{
              privateChannels
            }}</span>
            <span class="d-flex justify-center red--text my-3"
              ><v-icon color="red">mdi-arrow-down</v-icon> 2.3</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <!-- End of Private Channels -->
      <!-- Messages -->
      <v-col sm="6" md="6" lg="4">
        <v-card elevation="0">
          <v-toolbar flat>
            <v-toolbar-title class="body-1 font-weight-medium"
              >Messages</v-toolbar-title
            >
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="d-flex flex-column">
            <span class="display-2 black--text d-flex justify-center">{{
              messages
            }}</span>
            <span class="d-flex justify-center success--text my-3"
              ><v-icon color="success">mdi-arrow-up</v-icon> 2.3</span
            >
          </v-card-text>
        </v-card>
      </v-col>
      <!-- End of Messages -->
      <!-- Graphical representation of messages exchanged -->
      <v-col cols="12">
        <v-card class="mx-auto text-center" color="white" dark max-width="800">
          <v-card-text>
            <v-sheet color="white">
              <v-sparkline
                :value="value"
                color="indigo"
                height="100"
                padding="24"
                stroke-linecap="round"
              >
                <template v-slot:label="item"> {{ item.value }} </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="text-h4 font-weight-thin textColor">
              Messages in Last 24h
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- End of Graphical representation of messages exchanged -->
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isActive: true,
      data: {},
      publicChannels: "",
      privateChannels: "",
      messages: "",
      users: "",
      companies: "",
      teams: "",
      dates: ["2019-09-10", "2019-09-20"],
      value: [423, 446, 675, 510, 590, 610, 760],
    };
  },
  methods: {
    deleteComponent() {
      if (this.dates !== [null, null]) {
        this.dates.length = 0;
        this.isActive = false;
      }
    },
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  beforeMount() {
    let tempData = localStorage.getItem("data");
    this.data = JSON.parse(tempData);
    this.companies = this.data.companies;
    this.publicChannels = this.data.public_channels;
    this.privateChannels = this.data.private_channels;
    this.messages = this.data.messages;
    this.users = this.data.users;
    this.teams = this.data.teams;
  },
};
</script>

<style lang="scss" scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.textColor {
  color: black;
}
</style>
